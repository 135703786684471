@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700;800&display=swap');

:root {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  /* --tw-color-primary-50: 240 249 255; */
  /* Light Green */
  --tw-color-primary-50: 48 217 138;
  --tw-color-primary-100: 127 17 224;

  /* BLue */
  --tw-color-primary-200: 127 17 224;
  --tw-color-primary-300: 231 247 247;

  /*Amber */
  --tw-color-primary-400: 247 153 28;
  --tw-color-primary-500: 254 245 232;

  --tw-color-primary-600: 2 132 199;
  --tw-color-primary-700: 3 105 161;
  --tw-color-primary-800: 7 89 133;
  --tw-color-primary-900: 12 74 110;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #f0f9ff */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #e0f2fe */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #bae6fd */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #7dd3fc */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #38bdf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #0ea5e9 */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #0284c7 */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #0369a1 */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #075985 */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #0c4a6e */
  /* #endregion  /**======== Primary Color =========== */
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  --sidebar-width: 300px;

  --dark-sea-blue: #093e45;
  --active-link-color: #0e606a;
  --sea-blue: #51cbff;
  --placeholder-color: #ccd2e3;
  --bg-color: #e5e5e5;
  --dark-light: #202124;

  --gray: #808080;
  --green: #30d988;
  --light-gray: #f3f7fa;

  background-color: var(--bg-color);
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* background */
.bg-dark-blue {
  background-color: var(--dark-sea-blue);
}

.bg-sea-blue {
  background-color: var(--sea-blue);
}

.bg-gray {
  background-color: var(--gray);
}

.bg-green {
  background-color: var(--green);
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.bg-body {
  background-color: var(--bg-color);
}

/* Text */
.text-sea-blue {
  color: var(--sea-blue);
}

.text-gray {
  color: var(--gray);
}

.text-light-gray {
  color: var(--light-gray);
}

.text-green {
  color: var(--green);
}

/* Border */

.border-green {
  border-color: var(--green);
}

.glass-effect {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
}

/* .container {
  @apply max-w-[1600px];
} */

/* shadow */
/* .base-shadow {
  box-shadow: 0px 5px 10px #f1f2fa;
} */

/* scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply dark:bg-gray-800;
  background-color: var(--light-gray);
}

::-webkit-scrollbar-thumb {
  background-color: var(--green);
  border-radius: 2em;
}

/* Hide up and down in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#company-notes::-webkit-scrollbar {
  display: none;
}

#notes-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#company-notes,
#notes-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tooltip {
  width: 360px;
  white-space: normal;
}

svg {
  fill: currentColor;
}
